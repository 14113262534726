* {
	padding: 0px;
	margin: 0px;
	border: 0px;
}
*,
*:before,
*:after {
	box-sizing: border-box;
}
:focus,
:active {
	// outline: none;
}
a:focus,
a:active {
	// outline: none;
}
html,
body {
	height: 100%;
	min-width: $minWidth + px;
}
body {
	color: $mainColor;
	line-height: 1;
	font-family: $fontFamily;
	font-size: $fontSize;
	//text-rendering: optimizeLegibility;
	-ms-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
input,
button,
textarea {
	font-family: $fontFamily;
	font-size: inherit;
	line-height: inherit;
}
button {
	cursor: pointer;
	color: inherit;
	background-color: transparent;
}
a {
	color: inherit;
	text-decoration: none;
}
ul li {
	list-style: none;
}
img {
	vertical-align: top;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: inherit;
	font-size: inherit;
}
