.hamburger-react {
	position: relative;
	z-index: 101;
	display: none;
	@media (max-width: $mobile) {
		display: block;
	}
}

.title {
	top: toRem(20);
	@include adaptiveValue('font-size', 36, 25);
	font-weight: 700;
	text-align: center;
	&--white {
		color: #fff;
	}
}

.text {
	color: var(--neutral-800, #292e3d);
	text-align: center;
	@include adaptiveValue('font-size', 20, 16);
	line-height: normal;
	p {
		margin-bottom: toRem(10);
	}
	p + p {
		margin-bottom: toRem(0);
	}
}

.btn {
	height: toRem(53);
	display: inline-flex;
	padding: toRem(15) toRem(20);
	background-color: var(--neutral-white, #fff);
	justify-content: center;
	align-items: center;
	font-size: toRem(18);
	font-weight: 600;
	transition: all 0.3s ease 0s;
	&--rounded {
		border-radius: toRem(6);
	}
	@media (any-hover: hover) {
		&:hover {
			transition: all 0.3s ease 0s;
			background-color: var(--secondary);
			color: var(--neutral-white);
		}
	}
	@media (max-width: $mobile) {
		width: 100%;
	}
	&--blue {
		padding: toRem(15) toRem(30);
		border-radius: toRem(2);
		background: var(--primary, #2947a9);
		color: var(--neutral-white, #fff);
		font-size: toRem(18);
		font-weight: 600;
	}
	&--border {
		color: #fff;
		background-color: transparent;
		border-radius: 2px;
		border: 2px solid var(--neutral-white, #fff);
		@media (any-hover: hover) {
			&:hover {
				color: #000;
				transition: all 0.3s ease 0s;
				background-color: #fff;
			}
		}
	}
}

.link {
	@media (any-hover: hover) {
		&:hover {
			text-decoration: underline;
		}
	}
}
// Slider ========================================================================================================================================================

.slider-content {
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 2;
	display: flex;
	flex-direction: column;
	background: #3559c7;
	width: toRem(416);
	@media (max-width: $mobile) {
		width: 100%;
	}
	// .slider-content__body
	&__body {
		min-height: toRem(150);
		color: var(--neutral-white, #fff);
		padding: toRem(20);
		display: flex;
		flex-direction: column;
		gap: toRem(20);
		align-items: center;
		text-align: center;
	}
	// .slider-content__title
	&__title {
		font-size: toRem(20);
		font-weight: 700;
	}
	// .slider-content__about
	&__about {
		flex: 1 1 auto;
		font-size: toRem(24);
		display: flex;
		align-items: center;
	}
}
.slider-btns {
	height: toRem(40);
	display: flex;
	@media (max-width: $mobile) {
		height: toRem(60);
	}
}
.slider-btn {
	color: #fff;
	padding: toRem(20);
	font-size: toRem(18);
	flex: 1 1 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: toRem(10);
	background: var(--neutral-800, #292e3d);
	transition: all 0.3s ease 0s;
	// .slider-btn--next
	&--next {
		flex-direction: row-reverse;
		img {
			transform: rotate(180deg);
		}
	}
	@media (any-hover: hover) {
		&:hover {
			transition: all 0.3s ease 0s;
			background-color: var(--secondary);
		}
	}
}
// Footer-Links ========================================================================================================================================================

.footer-links {
	&:not(:last-child) {
		@include adaptiveValue('margin-bottom', 38, 20);
	}
	// .footer-links__link
	&__link {
	}
}
.link-footer {
	display: flex;
	align-items: center;
	column-gap: toRem(10);
	&:not(:last-child) {
		@include adaptiveValue('margin-bottom', 20, 16);
	}
	// .link-footer__label
	&__label {
		flex: 0 0 toRem(100);
		color: var(--primary, #2947a9);
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		font-variant: all-small-caps;
	}
	// .link-footer__link
	&__link {
		flex: 1 1 100%;
		color: var(--neutral-800, #292e3d);
		font-family: Work Sans;
		@include adaptiveValue('font-size', 20, 16);
		@media (any-hover: hover) {
			&:hover {
				text-decoration: underline;
			}
		}
	}
}

// Subscribe ========================================================================================================================================================
.subscribe {
	&:not(:last-child) {
		@include adaptiveValue('margin-bottom', 30, 0);
	}
	// .subscribe__form
	&__form {
		display: flex;
		flex-direction: column;
		row-gap: toRem(15);
	}
	// .subscribe__label
	&__label {
		color: var(--primary, #2947a9);
		font-size: toRem(20);
		font-variant: all-small-caps;
		font-weight: 500;
	}
	// .subscribe__row
	&__row {
		display: flex;
		gap: toRem(10);
		@media (max-width: $mobileSmall) {
			flex-direction: column;
		}
	}
	// .subscribe__input
	&__input {
		flex: 1 1 auto;
		padding: toRem(10) toRem(8);
		border-radius: 4px;
		border: 1px solid var(--neutral-100, #e0e3eb);
		background: #fff;
		font-size: toRem(18);
		&::placeholder {
			color: var(--neutral-300, #a3aac2);
		}
		&._correct {
			outline-color: green;
		}
		&._error {
			outline-color: red;
		}
	}
	// .subscribe__btn
	&__btn {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		border-radius: 4px;
		background: var(--secondary, #f9995d);
		color: var(--neutral-white, #fff);
		text-align: center;
		font-size: 18px;
		font-weight: 600;
		line-height: normal;
		padding: toRem(10) toRem(20);
		@media (min-width: $mobileSmall) {
			flex: 0 1 toRem(136);
		}
		transition: all 0.3s ease 0s;
		@media (any-hover: hover) {
			&:hover {
				transition: all 0.3s ease 0s;
				background-color: #ff7824;
			}
		}
	}
}
// Socials ========================================================================================================================================================

.socials {
	margin-bottom: toRem(20);
	// .socials__label
	&__label {
		color: var(--primary, #2947a9);
		@include adaptiveValue('font-size', 20, 18);
		font-weight: 500;
		font-variant: all-small-caps;
		&:not(:last-child) {
			margin-bottom: toRem(15);
		}
	}
	// .socials__links
	&__links {
		display: flex;
		gap: toRem(20);
	}
	// .socials__link
	&__link {
		transition: all 0.3s ease 0s;
		@media (any-hover: hover) {
			&:hover {
				transition: all 0.3s ease 0s;
				transform: scale(1.05);
			}
		}
	}
}
// Statistics item ========================================================================================================================================================

.statistic-item {
	opacity: 0;
	top: toRem(20);
	display: flex;
	flex-direction: column;
	row-gap: toRem(5);
	position: relative;
	padding: toRem(30);
	border-radius: 4px;
	border: 1px solid var(--neutral-white, #fff);
	background: #fff;
	box-shadow: 0px 4px 6px -2px rgba(17, 24, 39, 0.05),
		0px 12px 16px -4px rgba(17, 24, 39, 0.1);
	&._show {
		transition: all 0.3s ease 0s;
		opacity: 1;
		top: 0;
	}
	@media (min-width: $mobile) {
		padding-right: toRem(60);
		&:nth-of-type(2n + 1) {
			align-self: flex-end;
			z-index: 2;
		}
		&:nth-of-type(2n) {
			align-self: flex-start;
			margin-top: toRem(-25);
			margin-bottom: toRem(-25);
		}
	}
	@media (max-width: $mobile) {
		flex-direction: row;
		&:not(:last-child) {
			margin-bottom: toRem(15);
		}
	}
	&__quantity {
		position: relative;
		@include adaptiveValue('font-size', 72, 36);
		font-weight: 600;
		z-index: 2;
		@media (max-width: $mobile) {
			justify-content: center;
			display: flex;
			align-items: center;
			flex-shrink: 0;
			@include adaptiveValue('width', 100, 65);
		}
	}
	// .statistic-item__text
	&__text {
		display: flex;
		align-items: center;
		padding-left: toRem(40);
		position: relative;
		color: var(--neutral-500, #667299);
		font-size: toRem(24);
		&::before {
			position: absolute;
			content: '';
			left: toRem(16);
			width: toRem(7);
			height: 100%;
			background: var(--secondary, #f9995d);
		}
	}
	// .statistic-item__icon
	&__icon {
		position: absolute;
		z-index: 3;
		@media (max-width: $mobile) {
			display: none;
		}
		img {
			width: toRem(80);
			height: toRem(80);
		}
	}
}
// Reputation-Item ========================================================================================================================================================
.reputation-item {
	@extend %itemanimation;
	flex: 1 1 300px;
	padding: 20px 20px 40px 20px;
	border-radius: 4px;
	border: 1.4px solid var(--neutral-100, #e0e3eb);
	background: #fff;
	display: flex;
	flex-direction: column;
	gap: 18px;
	// .item__icon
	&__icon {
	}
	// .item__title
	&__title {
		font-size: 20px;
		font-weight: 700;
	}
	// .item__text
	&__text {
		color: var(--neutral-400, #858ead);
	}
}
// Services-Item ========================================================================================================================================================

.services-item {
	@extend %itemanimation;
	border-radius: 4px;
	padding: 20px;
	background-color: var(--neutral-white);
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: 0px 0px 37px -2px rgba(17, 24, 39, 0.05);
	transition: all 0.3s ease 0s;
	&:nth-of-type(even) {
		background-color: var(--primary);
		.services-item__title {
			color: var(--neutral-white);
		}
	}
	@media (any-hover: hover) {
		&:hover {
			transition: all 0.3s ease 0s;
			transform: scale(1.05);
		}
	}
	&__icon {
		padding: 20px;
		border-bottom: 2px solid var(--neutral-100);
	}
	&__title {
		display: flex;
		align-items: center;
		padding: 20px;
		flex: 1 1 auto;
		font-weight: 600;
		font-size: 20px;
		text-align: center;
		color: var(--primary);
		@media (any-hover: hover) {
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
// Tabs ========================================================================================================================================================

.tabs-buttons {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	@include adaptiveValue('gap', 20, 15);
	@media (max-width: $mobile) {
		flex-direction: row;
		overflow: scroll;
		padding-bottom: toRem(8);
	}
	// .tab-buttons__btn
	&__btn {
		padding-left: toRem(16);
		@include adaptiveValue('font-size', 24, 18);
		font-weight: 500;
		line-height: normal;
		color: var(--neutral-200);
		position: relative;
		@media (max-width: $mobile) {
			padding-left: toRem(0);
		}
		&._active {
			font-weight: 700;
			color: var(--primary);
			@media (min-width: $mobile) {
				&::before {
					content: '';
					background-color: var(--primary);
					position: absolute;
					width: toRem(4);
					height: 100%;
					top: 0;
					left: 0;
				}
			}
		}
	}
}
.tab-projects {
	display: grid;
	grid-template-columns: minmax(toRem(200), toRem(384)) minmax(
			toRem(200),
			toRem(384)
		);
	gap: toRem(32);
	grid-auto-flow: row;
	@media (max-width: $mobileSmall) {
		grid-template-columns: 1fr;
	}
}

.tab-project {
	position: relative;
	overflow: hidden;
	// .tab-project__img-ibg
	&__img-ibg {
		display: block;
		padding-bottom: 100%;
		transition: all 0.3s ease 0s;
		@media (any-hover: hover) {
			&:hover {
				transition: all 0.3s ease 0s;
				scale: 1.05;
			}
		}
	}
	// .tab-project__content
	&__content {
		color: var(--neutral-white);
		padding: toRem(12);
		background-color: var(--primary);
		left: 0;
		bottom: 0;
		width: 100%;
		position: absolute;
	}
	// .tab-project__title
	&__title {
		display: block;
		font-size: toRem(20);
		font-weight: 700;
		line-height: normal;
		&:not(:last-child) {
			margin-bottom: toRem(12);
		}
	}
	// .tab-project__adress
	&__adress {
	}
}
// Project-Display ========================================================================================================================================================
.project {
	margin-top: toRem(100);
	@include adaptiveValue('margin-bottom', 50, 015);
	// .project__container
	&__container {
		display: flex;
		gap: toRem(15);
		@media (max-width: $mobile) {
			flex-direction: column-reverse;
		}
	}
	// .project__img-block
	&__img-block {
		flex: 1 1 50%;
	}
	// .project__img
	&__img-ibg {
		padding-bottom: 70%;
	}
	// .project__content
	&__content {
	}
}
.content-project {
	flex: 1 1 50%;
	display: flex;
	flex-direction: column;
	gap: toRem(15);
	// .content-project__titlw
	&__title {
	}
	// .content-project__adress
	&__adress {
	}
	// .content-project__type
	&__type {
	}
	// .content-project__description
	&__description {
	}
}
// Form ========================================================================================================================================================
.form {
	display: grid;
	@include adaptiveValue('row-gap', 20, 10);
	// .form__inputs
	&__inputs {
		display: grid;
		@include adaptiveValue('column-gap', 30, 15);
		@include adaptiveValue('row-gap', 20, 10);
		@media (min-width: $mobile) {
			grid-template-columns: repeat(2, 1fr);
			grid-auto-flow: row;
		}
	}
	// .form__input
	&__input,
	&__message {
		width: 100%;
		border-radius: 4px;
		border: 1px solid var(--neutral-100, #e0e3eb);
		background: #fff;
		padding: 10px 8px;
		&:focus {
			&::placeholder {
				transition: all 0.3s ease 0s;
				opacity: 0;
			}
		}
	}

	// .form__message
	&__message {
		width: 100%;
	}
	// .form__advert
	&__advert {
		color: var(--neutral-500, #667299);
		line-height: normal;
		span {
			color: #c40303;
		}
	}
	// .form__button
	&__button {
		width: percent(271, 600);
		justify-self: center;
		@media (max-width: $mobile) {
			width: 100%;
		}
	}
}
// Dialog ========================================================================================================================================================
.dialog {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed !important;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.22);
	z-index: 100;
	width: 100%;
	height: 100%;
	// .dialog__body
	&__body {
		border-radius: toRem(10);
		padding: toRem(30);
		position: relative;
		text-align: center;
		background-color: #fff;
		width: 30%;
	}
	// .dialog__close
	&__close {
		position: absolute;
		top: toRem(5);
		right: toRem(5);
	}
	// .dialog__text
	&__text {
	}
}
