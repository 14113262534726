//Підключення шрифту
@mixin font($font_name, $file_name, $weight, $style) {
	@font-face {
		font-family: $font_name;
		font-display: swap;
		src:
			url("../fonts/#{$file_name}.woff2") format("woff2"),
			url("../fonts/#{$file_name}.woff") format("woff");
		font-weight: #{$weight};
		font-style: #{$style};
	}
}
//Percent
@function percent($px, $from: 100) {
	$result: math.div($px, $from) * 100%;
	@return $result;
}
//REM
@function toRem($px, $current: 16) {
	$result: math.div($px, $current) + rem;
	@return $result;
}
//EM
@function em($px, $current: 16) {
	$result: math.div($px, $current) + em;
	@return $result;
}

//Currency
@mixin currency($sym) {
	&::after {
		content: "#{$sym}";
	}
}

// Grids
@mixin gridCards($type: fit, $min: 280px, $max: 1fr, $gap: 30px) {
	display: grid;
	gap: $gap;
	grid-template-columns: repeat(auto-#{$type}, minmax($min, $max));
}

// Адаптивна властивість (clamp)
@mixin adaptiveValue($property, $startSize, $minSize, $keepSize: 0, $widthFrom: $containerWidth, $widthTo: $minWidth) {
	@if ($startSize==0) {
		$startSize: 0.000001;
	}
	@if ($minSize==0) {
		$minSize: 0.000001;
	}

	// Для calc();
	$addSize: math.div($startSize - $minSize, 16);

	@if ($widthFrom == $containerWidth and $maxWidthContainer == 0) {
		$widthFrom: $maxWidth;
	}

	// Брейк-поїнти в EM
	$widthFromMedia: em($widthFrom);
	$widthToMedia: em($widthTo);

	// Формула плаваючого значення
	// Источник: https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/
	$slope: math.div(($startSize - $minSize), ($widthFrom - $widthTo));
	$yIntersection: -$widthTo * $slope + $minSize;
	@if ($yIntersection==0) {
		$yIntersection: 0.000001;
	}
	$flyValue: #{toRem($yIntersection)}" + " #{$slope * 100}vw;

	// Отримання значення властивості
	$propertyValue: #{"clamp(" toRem($minSize) "," $flyValue "," toRem($startSize) ")"};
	// Якщо негативні значення
	@if ($minSize > $startSize) {
		$propertyValue: #{"clamp(" toRem($startSize) "," $flyValue "," toRem($minSize) ")"};
	}

	// Встановлюємо значення за замовчуванням
	@if $keepSize != 1 and $keepSize != 3 {
		@media (min-width: $widthFromMedia) {
			#{$property}: toRem($startSize);
		}
	}
	// Адаптуємо розмір у проміжку між зазначеними ширинами в'юпорту
	@media (min-width: $widthToMedia) and (max-width: $widthFromMedia) {
		// Якщо підтримується clamp();
		@supports (#{$property}: $propertyValue) {
			#{$property}: $propertyValue;
		}
		// Якщо не підтримується clamp();
		@supports not (#{$property}: $propertyValue) {
			#{$property}: calc(#{toRem($minSize)} + #{$addSize} * (100vw - #{toRem($widthTo)}) / #{math.div($widthFrom, 16) - math.div($widthTo, 16)});
		}
	}
	@if $keepSize != 1 and $keepSize != 2 {
		@media (max-width: $widthToMedia) {
			#{$property}: toRem($minSize);
		}
	}
}
