.header {
	z-index: 50;
	width: 100%;
	top: 0;
	left: 0;
	position: fixed;
	&::before {
		position: absolute;
		top: 0;
		left: 0;
		content: '';
		width: 100%;
		height: 100%;
		background-color: var(--neutral-white);
		z-index: 5;
	}
	// .header__container
	&__container {
		min-height: toRem(80);
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: toRem(15);
		padding-block: toRem(10);
	}
	// .header__logo
	&__logo {
		position: relative;
		z-index: 5;
	}
	// .header__menu
	&__menu {
	}
}
.menu {
	// .menu__body
	&__body {
		@media (max-width: $mobile) {
			overflow: auto;
			position: fixed;
			z-index: 2;
			width: 100%;
			height: 100%;
			top: -100%;
			left: 0;
			padding: toRem(120) toRem(15) toRem(30) toRem(15);
			background-color: var(--primary);
			transition: all 0.3s ease 0s;
			.menu-open & {
				transition: all 0.3s ease 0s;
				top: 0;
			}
		}
	}
	// .menu__list
	&__list {
		position: relative;
		z-index: 5;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		@include adaptiveValue('column-gap', 50, 25);
		row-gap: toRem(3);
		@media (max-width: $mobile) {
			flex-direction: column;
			align-items: center;
			row-gap: toRem(25);
			font-size: toRem(25);
		}
	}
	// .menu__item
	&__item {
	}
	// .menu__link
	&__link {
		transition: all 0.3s ease 0s;
		@media (any-hover: hover) {
			&:hover {
				transition: all 0.3s ease 0s;
				color: var(--secondary);
			}
		}
		@media (max-width: $mobile) {
			color: var(--neutral-white);
			font-weight: 500;
		}
	}
}
