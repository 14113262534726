:root {
	/* Color styles */
	--primary: rgba(41, 71, 169, 1);
	--secondary: rgba(249, 153, 93, 1);
	--neutral-900: rgba(20, 23, 31, 1);
	--neutral-800: rgba(41, 46, 61, 1);
	--neutral-700: rgba(61, 68, 92, 1);
	--neutral-600: rgba(82, 91, 122, 1);
	--neutral-500: rgba(102, 114, 153, 1);
	--neutral-400: rgba(133, 142, 173, 1);
	--neutral-300: rgba(163, 170, 194, 1);
	--neutral-200: rgba(194, 199, 214, 1);
	--neutral-100: rgba(224, 227, 235, 1);
	--neutral-50: rgba(246, 248, 247, 1);
	--neutral-white: rgba(255, 255, 255, 1);

	/* Text-size styles */
	--subtitle: 20px;
	--caption: 16px;
	--heading-1: 72px;
	--heading-2: 60px;
	--heading-3: 48px;
	--heading-4: 36px;
	--heading-5: 30px;
	--body-large: 24px;
	--body-medium: 20px;
	--body-default: 18px;

	/* Effect styles */
	--shadow-1: 0px 8px 8px rgba(17, 24, 39, 0.04),
		0px 20px 24px rgba(17, 24, 39, 0.1);
	--shadow-2: 0px 4px 6px rgba(17, 24, 39, 0.05),
		0px 12px 16px rgba(17, 24, 39, 0.1);
}
