[class*='-ibg'] {
	position: relative;
	img {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
	}
}
[class*='-ibg--contain'] {
	img {
		object-fit: contain;
	}
}

// Шаблони (заготівлі)
// @extend %ім'я шаблону;
// Сніппет (SCSS): ex

// Лічильник для списку
%listCounter {
	list-style-type: none;
	counter-reset: item;
	li {
		position: relative;
		&:before {
			counter-increment: item;
			content: counter(item);
			position: absolute;
			left: 0;
			top: 0;
		}
	}
}
// Адаптивне відео
%responsiveVideo {
	position: relative;
	overflow: hidden;
	height: 0;
	padding-bottom: 56.25%;
	video,
	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
// Відео як фон
%videoBackground {
	video,
	iframe,
	object,
	embed {
		position: fixed;
		top: 50%;
		left: 50%;
		min-width: 100%;
		min-height: 100%;
		width: auto;
		height: auto;
		z-index: -100;
		transform: translateX(-50%) translateY(-50%);
		background-size: cover;
	}
}
// Сірий фільтр
%grayfilter {
	transition: all 0.3s ease 0s;
	filter: grayscale(1);
	@media (any-hover: hover) {
		&:hover {
			filter: grayscale(0);
		}
	}
}
// Скасувати виділення
%noselect {
	user-select: none;
}
// Дзеркальне відображення
%mirror {
	transform: scale(-1, 1);
}
// Плавний скролл
%smoothscroll {
	-webkit-overflow-scrolling: touch;
}
// Сховати скролл
%hidescroll {
	&::-webkit-scrollbar {
		display: none;
	}
}
%customscroll {
	/* Track */
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background: red;
	}
}
%titleanimation {
	position: relative;
	top: toRem(20);
	opacity: 0;
	._in-view & {
		transition: all 0.3s ease 0s;
		top: 0;
		opacity: 1;
	}
}

%itemanimation {
	opacity: 0;
	position: relative;
	left: toRem(-20);
	&._show {
		left: 0;
		transition: all 0.3s ease 0s;
		opacity: 1;
	}
}

%bottomslideanimation {
	top: toRem(20);
	opacity: 0;
	._in-view & {
		transition: all 0.3s ease 0s;
		opacity: 1;
		top: 0;
	}
}
